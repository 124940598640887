$background-attachment: scroll;
$background-position: 0 0;
$background-repeat: repeat;
$background-size: auto;
$font-family-bold: "SangBleuKingdom-Bold", sans-serif;
$font-family-regular: "SangBleuKingdom-Regular", sans-serif;
$font-family-secondary: "SuisseIntl-Regular", sans-serif;
$font-family-partner: "FAZGoldSans-Regular", sans-serif;
$font-family-partner-bold: "FAZGoldSans-Bold", sans-serif;
$color-night: #110A35;
$color-btn: #E61849;
$color-light-grey: #E8EDEE;
$color-grey: #9D9D9D;
$color-deep-grey: #333332;
$color-exclusive: #120A37;
$color-coupon-bg: #E7EDEE;
$color-ashes: #4F5764;
$color-berry: #8801FF;
$color-cloud: #ECF0F1;
$color-cherry: #E6314C;
$color-freedom: #2ECC71;
$color-juicy: #F05632;
$color-ready: #E60000;
$color-royal: #7200FF;
$color-sky: #07C;
$color-border: #D8D8D8;
$color-card-bg: #F9F9F9;
$color-title: #262626;
$color-headline: #404040;
$color-subheadline: #737373;
$color-text: #404040;
$color-bg-hero: #BBB;
$color-inactive: #C6CFD1;
$color-body-bg: #ECECEC;
$color-header-bg: #F6F6F6;
$color-white: #FFF;
$color-black: #000;
$color-partner-header: #C50101;
$color-partner-footer: #E3E3E3;
$color-partner-footer__title: #111;
$color-partner-footer__hover: #C60000;
$color-marshmallow-pink: #FBDCE4;
$color-cta-clickout: #110C35;
$color-cta: $color-btn;
$color-ep-label: $color-cta-clickout;

// Editors Pick / Top Coupon
$coupon-editors-pick-logo__width--mobile: 111px;
$coupon-editors-pick__grid-template-rows--mobile: 28px auto auto auto;
$coupon-editors-pick-tag--icon: false;

// Offer Carousel
$offer-carousel-main-color: $color-btn;

// Curated coupons
$curated__margin: 45px 0 0 0;
$curated-heading__margin: -16px 0 0 -10px;
$curated-heading__margin--mobile: -10px 0 0 -16px;
$curated-heading__padding: 0;
$curated-heading__font-weight: 500;
$curated-heading__font-size: 20px;
$curated-heading__font-size--mobile: 20px;
$curated-section__grid-template-column--left: 160px;
$curated-section__margin: 0;
$curated-section__padding: 12px 20px 8px 0;
$curated-logo__margin: 0;
$curated-logo__padding: 0;
$curated-logo__border: 1px solid $color-grey;
$curated-title__margin: 0 0 8px;
$curated-title__margin--mobile: 0 0 8px;
$curated-subtitle__line-height: 26px;
$curated-subtitle__line-height--mobile: 26px;

// Author widget
$colors: (
  "color-border": $color-grey
);
$author-widget-name__color: $color-night;
$author-widget-profession__color: $color-black;
$author-widget-bio__color: $color-night;
$author-widget-additional-info__color: $author-widget-bio__color;
$author-widget-reviewed__color: $author-widget-bio__color;
$author-widget-page-link__color: $color-black;
$author-widget-page-link-before__bg-color: $color-black;
$icon-link: false !default;
$icon-circle: true !default;
$author-widget-page-link-before__content: " " !default;

// Multipurpose SEO widget
$multipurpose-text-a__font-size: 16px;

// Responsive breakpoints
$xxs: "(max-width: 425px)";
$xs: "(max-width: 575px)";
$sm: "(max-width: 767px)";
$md: "(max-width: 991px)";
$lg: "(max-width: 1199px)";
$min-xs: "(min-width: 576px)";
$min-sm: "(min-width: 768px)";
$min-md: "(min-width: 992px)";
$min-lg: "(min-width: 1200px)";

@font-face {
  font-weight: 500;
  font-family: SangBleuKingdom-Regular;
  font-style: normal;
  font-display: swap;
  src: url("https://gutscheine.faz.net/static/fonts/64/SangBleuKingdom-Regular.woff2") format("woff2");
}

@font-face {
  font-weight: 700;
  font-family: SangBleuKingdom-Bold;
  font-style: normal;
  font-display: swap;
  src: url("https://gutscheine.faz.net/static/fonts/64/SangBleuKingdom-Bold.woff2") format("woff2");
}

@font-face {
  font-weight: 500;
  font-family: SuisseIntl-Regular;
  font-style: normal;
  font-display: swap;
  src: url("https://gutscheine.faz.net/static/fonts/64/SuisseIntl-Regular.woff2") format("woff2");
}

@font-face {
  font-weight: 500;
  font-family: FAZGoldSans-Regular;
  font-style: normal;
  font-display: swap;
  src: url("https://gutscheine.faz.net/static/fonts/64/FAZGoldSansWeb-Regular.woff2") format("woff2");
}
