.th-header {
  position: relative;
  display: grid;
  grid-template-areas:
    "home products . services"
    "logo logo logo logo";
  grid-template-columns: 72px 1fr 1fr 150px;
  align-items: center;
  padding: 4px 0 28px;

  @media #{$sm} {
    grid-row-gap: 0;
    grid-template-areas: "logo";
    grid-template-columns: 1fr;
    padding: 20px 0;
  }

  .th-header__home {
    grid-area: home;
    justify-self: flex-end;
    width: 17px;
    height: 17px;
    margin-top: 6px;
    padding: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.996 0.743408L28 14.414L26.6697 15.7767L14.0121 3.4205L1.34661 16.086L0 14.7394L13.996 0.743408Z' fill='%23110A35'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.1607 10.9692V28.1088H3.84717V11.2866H5.75156V26.2044H22.2563V10.9692H24.1607Z' fill='%23110A35'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    @media #{$sm} {
      display: none;
    }

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.996 0.743408L28 14.414L26.6697 15.7767L14.0121 3.4205L1.34661 16.086L0 14.7394L13.996 0.743408Z' fill='%23C50101'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.1607 10.9692V28.1088H3.84717V11.2866H5.75156V26.2044H22.2563V10.9692H24.1607Z' fill='%23C50101'/%3E%3C/svg%3E%0A");
    }
  }

  .th-header__products {
    position: relative;
    grid-area: products;
    justify-self: flex-start;
    margin-left: 10px;
  }

  .th-header__services {
    position: relative;
    grid-area: services;
    justify-self: center;
  }

  .th-header__logo {
    grid-area: logo;
    justify-self: center;
    width: 356px;
    height: 70px;
    background-image: url("https://gutscheine.faz.net/static/images/64/faz-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;

    @media #{$sm} {
      width: 200px;
      height: 41px;
      margin-left: 27px;
      background-image: url("https://gutscheine.faz.net/static/images/64/logo_mob.svg");
    }
  }

  .gh-TopNav_Products {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media #{$sm} {
      display: none;
    }

    &:hover {
      color: $color-partner-header;

      .gh-TopNav_ProductsDropdown {
        display: block;
      }
    }

    &Dropdown {
      position: absolute;
      top: 25px;
      left: 50%;
      z-index: 100002;
      display: none;
      width: auto;
      padding-top: 0.9375rem;
      transform: translateX(-50%);

      .gh-TopNav_ProductsList {
        position: relative;
        padding: 0.625rem 0 0.3125rem;
        list-style: none;
        background: $color-white;
        border: 0.0625rem solid $color-border;
        border-radius: 0.125rem;
      }

      .gh-TopNav_ProductsList::before,
      .gh-TopNav_ProductsList::after {
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -0.4375rem;
        border: solid transparent;
        border-right: 0.4375rem solid transparent;
        border-bottom: 0.625rem solid $color-border;
        border-left: 0.4375rem solid transparent;
        content: " ";
        pointer-events: none;
      }

      .gh-TopNav_ProductsList::after {
        bottom: calc(100% - 0.125rem);
        border-bottom-color: $color-white;
      }

      .gh-TopNav_ProductLink {
        display: block;
        padding: 4px 18px;
        color: $color-night;
        font-weight: 700;
        font-size: 13px;
        font-family: $font-family-secondary;
        white-space: nowrap;
        text-decoration: none;

        &:hover {
          color: $color-partner-header;
        }
      }

      .o-Js-is-disabled .gh-TopNav_ProductLink,
      .wf-active:not(.wf-ignore) .gh-TopNav_ProductLink {
        font-family: $font-family-partner;
      }
    }

    .gh-TopNav_Link {
      position: relative;
      padding: 16px 22px 5px;
      font-size: 10px;
      font-family: $font-family-secondary;
      text-transform: uppercase;

      &::before {
        position: absolute;
        top: 13px;
        left: -2px;
        display: block;
        width: 22px;
        aspect-ratio: 1/1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='345.333' height='258.667' version='1.0' viewBox='0 0 259 194'%3E%3Cpath d='M98.5 3.4c-6.4 2-10 4.2-15.2 9.3-8.5 8.3-12.9 20.8-10.4 29.9 4.5 16.6 24.6 21.9 39.2 10.3 5.4-4.3 4.9-5.3-1.3-2.2-7.4 3.7-20.5 4.4-27.1 1.5-15.1-6.7-9.7-25.9 8.3-29 9.6-1.7 17.7.2 36.6 8.7 11.8 5.3 20.5 7.5 26.3 6.7 9.3-1.3 19.3-8.2 26.1-18.1 2.5-3.7 6.2-12.6 5.5-13.3-.2-.2-2.7 1.5-5.5 3.6-10.4 7.9-16.5 7.8-36.8-.3C124.6 2.6 108.8.2 98.5 3.4z'/%3E%3Cpath d='M132.9 43c-6.1 4.1-9.7 7.5-15.1 14.1-11.5 14.3-10.1 30.7 3.6 40.5 13.9 10.1 16.6 12.5 19.6 17.6 2.8 4.8 3.1 6 2.8 12.5-.2 3.9-.7 7.5-1.1 7.9-.4.4-3.3-1.8-6.5-4.8-7-6.7-10-8.3-17.7-9.7-15.8-2.9-33.1 6.1-39.7 20.6-1.8 4-2.2 6.7-2.3 15.3 0 9.5.3 11 2.8 16.2 11 22.3 44.8 25.4 63.1 5.8 3.7-4 9.6-13.5 9.6-15.5 0-.3-2.6 2.1-5.8 5.3-9.5 9.8-20.8 14.2-36.2 14.2-19.1 0-29-7.5-29-22 .1-7.9 8.4-18.3 16.8-20.8 6.2-1.9 14.7-1.5 19.2.8 3.9 2 10.9 10.2 11 12.7 0 1.7 2 1.7 2 0 0-2.4 6.4-9 15.4-15.7 16.4-12.3 19-15.8 19.1-26.5 0-9.6-3.4-16.5-12.1-24.3-5.7-5.1-6.4-6.1-6.4-9.4 0-2.7.8-4.7 2.9-7l2.9-3.3-.5 4.7c-.5 4.3-.3 5 2.5 7.8 8.9 8.9 23-3 16.2-13.8-2.8-4.6-7.9-6.6-13.1-5.2-5.6 1.5-9.6 5.5-12.3 12.3l-2.3 5.8-4.4-3.3c-11.6-8.9-12.8-20.5-3.3-31.6 2-2.3 3.4-4.2 3.1-4.2-.2 0-2.3 1.3-4.8 3z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
      }
    }
  }
}

.nav li a:hover {
  color: unset;
  background: unset;
}

.pannacotta #header {
  background-color: $color-light-grey;

  .container {
    height: 42px;
  }

  .navbar-toggle {
    position: absolute;
    bottom: 10px;
    width: 25px;
    text-align: inherit;
  }

  .header-wrapper {
    #main-menu {
      ul {
        li.has-sub-menu__categories {
          .pannacotta.sub-menu__categories {
            ul.nav li {
              a {
                color: $color-night;
              }
            }
          }
        }
      }
    }
  }

  #main-menu {
    ul {
      li,
      li.has-sub-menu__categories,
      li.has-sub-menu__specials,
      li.active {
        height: 41px;

        &:hover {
          background-color: $color-night;

          a {
            color: $color-white;
          }
        }

        a {
          color: $color-night;
          font-weight: 400;
          font-size: 14px;
          font-family: $font-family-secondary;
          line-height: 18px;
          border-radius: 0;

          @media #{$min-md} {
            padding: 10px 0 9px;
          }
        }

        .pannacotta.sub-menu__categories,
        .pannacotta.sub-menu__specials {
          top: 41px;
          right: 50%;
          width: 100%;
          background-color: $color-light-grey;
          border-top: 2px solid $color-night;
          transform: translateX(50%);

          ul.nav li {
            background-color: $color-light-grey;

            a {
              color: $color-night;
            }

            &:hover {
              background-color: $color-night;

              a {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

#header .navbar-toggle {
  @media #{$sm} {
    bottom: 10px;
    color: $color-night;
  }
}

.pannacotta#header {
  .header-wrapper {
    .col-xs-3.col-sm-4.col-sm-pull-6 {
      display: none;
    }

    .col-xs-3.col-sm-6.col-sm-push-6 {
      left: 0;
      width: 100%;

      .pannacotta.sub-menu__categories,
      .pannacotta.sub-menu__specials {
        ul {
          li:first-child {
            display: block;
          }
        }
      }

      #main-menu {
        ul {
          li.active {
            background-color: $color-night;

            a {
              color: $color-white;
            }
          }
        }

        .nav.navigation {
          & > li:first-child {
            display: none;
          }
        }
      }
    }
  }

  @media #{$sm} {
    height: 0;
  }
}

.pannacotta .sub-menu__shops {
  top: 200px;
  background-color: $color-light-grey;
  border-top: 2px solid $color-night;

  b {
    color: $color-night;
  }

  .list-circle {
    border: 2px solid $color-night;
  }

  ul {
    li {
      margin: 0;

      a {
        display: inline-block;
        width: calc(100% - 20px);
        margin-left: 0;
        padding: 5px 5px 5px 25px;
        color: $color-night;
      }

      &:hover {
        background-color: $color-night;

        a {
          color: $color-white;
        }

        span.list-circle {
          display: inline-block;
          border: 2px solid $color-white;
        }
      }
    }
  }
}

.sub-menu {
  a {
    margin-left: 20px;
    color: $color-night;
    font-size: 14px;
    font-family: $font-family-secondary;

    &:hover {
      color: $color-white;
      background-color: $color-night;
    }
  }

  p {
    &.text-center {
      margin: 0;
      padding: 0;

      a {
        display: block;
        margin-left: 0;
        padding: 15px 0 15px 20px;
      }
    }
  }
}

.list-three-cols {
  li {
    margin: 5px;
    padding-left: 6px;
  }
}

.flat-navmenu-default {
  background-color: $color-light-grey;

  .nav {
    li {
      a {
        color: $color-night;
        font-weight: 400;
        font-size: 14px;
        font-family: $font-family-secondary;
        line-height: 18px;

        &:hover {
          color: $color-white;
          background-color: $color-night;
        }
      }
    }
  }
}
